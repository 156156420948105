import React, { forwardRef } from 'react';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';

export const TitledPassword = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    value,
    onChange,
    readOnly = false,
    // suffix = null,
    className = '',
    error = null,
    required = false,
    inputConfig,
  } = props;

  return (
    <div className="field m-0">
      <label htmlFor={`txt_${id}`}>
        {required && <i className="text-red-400 pi pi-check mr-1" />}
        {title}
      </label>
      <Password
        id={`txt_${id}`}
        inputRef={ref}
        className="w-full"
        inputClassName={classNames(
          'w-full ' + className,
          readOnly && (className === '' ? 'bg-gray-100' : className),
          { 'p-invalid': error }
        )}
        placeholder="영문, 숫자포함 4자리 이상"
        // header={<h6>Pick a password</h6>}
        // footer={
        //   <React.Fragment>
        //     <Divider />
        //     <p className="p-mt-2">Suggestions</p>
        //     <ul className="p-pl-2 p-ml-2 p-mt-0" style={{lineHeight: '1.5'}}>
        //       <li>At least one lowercase</li>
        //       <li>At least one uppercase</li>
        //       <li>At least one numeric</li>
        //       <li>Minimum 8 characters</li>
        //     </ul>
        //   </React.Fragment>
        // }
        {...inputConfig}
        value={value}
        onChange={onChange}
        autoComplete="new-password"
        // toggleMask
      />
      <small id={`txt_err_${id}`} className="p-error">
        {error?.message}
      </small>
    </div>
  );
});

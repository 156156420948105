import * as Button from '../../Atoms/Button';
import * as Grouped from './../../../components/Atoms/Grouped';

import { PAYMENT_METHOD } from '../../../constants/Constants';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { BlockUI } from 'primereact/blockui';

import { CommonUtil } from '../../../utils/commonUtil';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import { RadioButton } from 'primereact/radiobutton';

import _ from 'lodash';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../../recoil/selectors';

import dayjs from 'dayjs';
import { PerfomancePayment } from '../../../services/PerformanceCheckService.js';

const performanceCheckService = ServiceProvider.performanceCheck;
const priceService = ServiceProvider.price;

const PaymentUpdateDialog = ({
  visible,
  onHide,
  dataId,
  getData,
  searchConditions,
  lazyParams,
  getSummary,
  myInfo,
}) => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const [isPossible, setIsPossible] = useState(true);
  const [codes, setCodes] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getDetailData = async (id) => {
    setLoading(true);
    try {
      const data = await PerfomancePayment.getDetail(id);
      if (data) {
        setData(data.paymentData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataId) {
      getDetailData(dataId);
    }
  }, [dataId]);

  const defaultValues = {
    dataId: 0,
    statementNumber: '',
    isRecheck: 'N',
    originDataId: null,
    originStatementNumber: null,
    checkDate: '',
    associationId: 1,
    associationCode: '',
    associationName: '',
    enterpriseId: 0,
    enterpriseCode: '',
    enterpriseName: '',
    shopId: 0,
    shopCode: '',
    shopName: '',
    inspectorId: 0,
    inspectorLoginId: '',
    inspectorName: '',
    customerCompanyName: '',
    customerCompanyContact: '',
    customerName: '',
    customerContact: '',
    vehicleType: '',
    assuranceType: '',
    insuranceCompany: '',
    insurancePrice: 0,
    insurancePricePaidAt: null,
    insurancePricePaidBy: 'TRAN',
    insurancePricePaidYn: 'N',
    insurancePriceRefundedAt: null,
    insurancePriceVat: 0,
    checkPriceItemId: '',
    checkPrice: 0,
    checkPricePaidAt: null,
    checkPricePaidBy: 'TRAN',
    checkPricePaidYn: 'N',
    checkPriceVat: 0,
    discountReason: '',
    discountedCheckPrice: 0,
    carName: '',
    licensePlateNumber: '',
    ewYn: 'N',
    insurancePolicyNumber: '',
    insurancePriceCode: '',
  };

  const {
    control,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues, reValidateMode: 'onSubmit' });

  const currentValues = useWatch({ control });

  const populateDefaultValues = useCallback(
    (data, defaultValues) => {
      for (const key in defaultValues) {
        if (data[key] !== undefined) {
          setValue(key, data[key]);
        } else {
          setValue(key, defaultValues[key]);
        }
      }
    },
    [setValue]
  );
  useEffect(() => {
    if (data) {
      populateDefaultValues(data, defaultValues);
    }
  }, [data, setValue, populateDefaultValues]);

  useEffect(() => {
    if (!!currentValues.associationCode) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [currentValues.associationCode]);

  //ANCHOR - update payment
  const updatePayment = async () => {
    if (!!currentValues.discountedCheckPrice && !currentValues.discountReason) {
      return window.cerp.toast.warn(
        '필수값 미입력',
        '할인사유를 입력해주세요.'
      );
    }

    const updateData = {
      ...currentValues,
    };

    // return console.log(updateData);
    try {
      const result = await performanceCheckService.updatePayment(updateData);

      if (result) {
        window.cerp.toast.success(
          `점검료 수정 완료`,
          `${data?.statementNumber}번 점검료 수정이 완료되었습니다.`
        );
        setData(result.data.paymentData);
        getData(searchConditions, lazyParams.page, lazyParams.rows);
        getSummary({
          ...searchConditions,
          associations: [myInfo.myAssociationId],
          enterprises: [myInfo.myEnterpriseId],
          shops: [myInfo.myShopId],
        });
      }
    } catch (error) {
      window.cerp.dialog.error(
        '점검료 수정 실패',
        error?.code
          ? `[${error?.code}] ${error?.message}`
          : '[서버 에러] 시스템 관리자에게 문의하세요.'
      );
    }
  };

  const setCheckPrice = useCallback(
    ({
      checkPriceTable = codes.checkPriceTable,
      selectedItemId = _.get(currentValues, 'checkPriceItemId'),
      discountedCheckPrice = _.get(currentValues, 'discountedCheckPrice'),
    } = {}) => {
      const { checkPriceTableId, checkPriceItems } = checkPriceTable || {};

      if (checkPriceItems) {
        const itemId = selectedItemId || _.get(checkPriceItems, '0.id');
        const item = _.find(checkPriceItems, { id: itemId });
        if (!_.get(currentValues, 'checkPriceItemId')) {
          setValue('checkPriceItemId', itemId);
        }

        const previousTotalCheckPrice = 0;
        const previousCheckPrice = 0;
        const previouscheckPriceVat = 0;

        const currentTotalCheckPrice = _.toNumber(_.get(item, 'price'));

        const currentcheckPriceVat =
          Math.floor(currentTotalCheckPrice / 11 / 10) * 10;
        const currentCheckPrice = currentTotalCheckPrice - currentcheckPriceVat;

        const isMoreExpensive =
          currentTotalCheckPrice - previousTotalCheckPrice >= 0;

        const checkPrice = isMoreExpensive
          ? currentCheckPrice - previousCheckPrice
          : 0;
        const checkPriceVat = isMoreExpensive
          ? currentcheckPriceVat - previouscheckPriceVat
          : 0;
        const totalCheckPrice = isMoreExpensive
          ? currentTotalCheckPrice - previousTotalCheckPrice
          : 0;

        setValue('checkPriceTableId', checkPriceTableId);
        setValue('checkPrice', _.isNaN(checkPrice) ? 0 : checkPrice);
        setValue('checkPriceVat', _.isNaN(checkPriceVat) ? 0 : checkPriceVat);

        return [totalCheckPrice, checkPriceVat, checkPrice];
      } else {
        setValue('checkPriceTableId', 0);
        setValue('checkPriceItemId', '');
        setValue('checkPrice', 0);
        setValue('checkPriceVat', 0);
        return [0, 0, 0];
      }
    },
    [codes.checkPriceTable, setValue, currentValues]
  );

  const calculatePrices = ({
    checkPrice = _.toNumber(_.get(currentValues, 'checkPrice')),
    checkPriceVat = _.toNumber(_.get(currentValues, 'checkPriceVat')),
    insurancePrice = _.toNumber(_.get(currentValues, 'insurancePrice')),
    insurancePriceVAT = _.toNumber(_.get(currentValues, 'insurancePriceVat')),
  } = {}) => {
    const summaryPrice = checkPrice + insurancePrice;
    const vat = checkPriceVat + insurancePriceVAT;
    const totalPrice = summaryPrice + vat;

    return [totalPrice, summaryPrice, vat];
  };

  const getCheckPriceTable = useCallback(
    async (
      associationId = 0,
      enterpriseId = 0,
      shopId = 0,
      checkPriceTableId
    ) => {
      let params = {
        associationId,
        enterpriseId,
        shopId,
        checkPriceTableId,
      };

      if (!shopId) {
        setIsPossible(false);
        return window.cerp.toast.warn('해당 성능점검장에서만 가능합니다.');
      }
      // console.log(shopId);
      try {
        const { data: checkPriceTable } = await priceService.getCheckPrice(
          params
        );
        // console.log('[점검료 조회]', checkPriceTable);
        setCodes((ps) => ({
          ...ps,
          checkPriceTable,
        }));
        const id = currentValues.checkPriceItemId;
        setCheckPrice({ checkPriceTable, id });
      } catch (error) {
        window.cerp.toast.warn('해당 성능점검장에서만 가능합니다.');
        setIsPossible(false);
      }
    },
    [currentValues.checkPriceItemId, setCheckPrice]
  );

  useEffect(() => {
    if (myInfoLoadable.contents && currentValues.checkPriceItemId) {
      const { associationInfo, enterpriseInfo, shopInfo } =
        myInfoLoadable.contents;

      getCheckPriceTable(
        associationInfo.associationId,
        enterpriseInfo.enterpriseId,
        shopInfo.shopId
      );
    }
  }, [myInfoLoadable.contents, currentValues.checkPriceItemId]);

  const getFormErrorMessage = (name) => {
    const error = _.get(errors, name);
    return <small className="p-error">{error?.message}</small>;
  };

  const totalCheckPrice =
    data?.checkPrice + data?.checkPriceVat - data?.discountedCheckPrice;
  const totalInsurance = data?.insurancePrice + data?.insurancePriceVat;

  return (
    <Dialog
      modal
      blockScroll
      header="점검료 수정"
      breakpoints={{ '960px': '90vw' }}
      visible={visible}
      style={{ width: '76vw' }}
      onHide={onHide}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button.Default
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
        </div>
      )}
    >
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="grid">
          <div className="col-12 my-2 py-0">
            <Panel header="1. 기본 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      점검일
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {dayjs(data?.checkDate).format('YYYY년 MM월 DD일')}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      매매상사
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {`${data?.customerCompanyName} ${
                        data?.customerCompanyContact &&
                        ` [${CommonUtil.Formatter.phone(
                          data?.customerCompanyContact
                        )}]`
                      }`}
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      고객
                    </th>
                    <td className="px-2 border-gray-300">
                      {`${data?.customerName} ${
                        data?.customerContact &&
                        `[${CommonUtil.Formatter.phone(data?.customerContact)}]`
                      }`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>

          <div className="col-12">
            <Panel header="2. 점검료 수정" className="">
              <div className="grid">
                <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2 ">
                  <Controller
                    control={control}
                    name={'checkPriceItemId'}
                    defaultValue=""
                    render={({ field, fieldState, formState }) => (
                      <>
                        <Grouped.TitledDropdown
                          id={field.name}
                          {...field}
                          title={'점검료 수정'}
                          options={_.get(
                            codes,
                            'checkPriceTable.checkPriceItems'
                          )}
                          filter={true}
                          classNameProps={true}
                          placeholder="없음"
                          optionLabel="title"
                          optionValue="id"
                          onChange={({ value }) => {
                            field.onChange(value);
                            setCheckPrice({
                              selectedItemId: value,
                            });
                          }}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
                <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2 ">
                  <Controller
                    control={control}
                    name={'totalCheckPrice'}
                    defaultValue={0}
                    render={({ field }) => (
                      <Grouped.TitledInputNumber
                        id={field.name}
                        title={'점검료 (VAT포함)'}
                        {...field}
                        readOnly
                        suffix=" 원"
                        value={
                          currentValues?.checkPrice +
                          currentValues?.checkPriceVat
                        }
                      />
                    )}
                  />
                </div>
                <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2">
                  <Controller
                    control={control}
                    name={'checkPricePaidBy'}
                    defaultValue=""
                    render={({ field }) => (
                      <Grouped.TitledDropdown
                        id={field.name}
                        title={'결제'}
                        {...field}
                        options={PAYMENT_METHOD}
                        onChange={({ value }) => {
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-12 sm:col-12 md:col-4 lg:col-4 mb-2">
                  <Controller
                    control={control}
                    name={'discountedCheckPrice'}
                    defaultValue={''}
                    render={({ field }) => (
                      <Grouped.TitledInputNumber
                        id={field.name}
                        title="할인 금액"
                        {...field}
                        onFocus={(e) => e.target.select()}
                        suffix=" 원"
                        onChange={(e) => {
                          field.onChange(e);

                          setCheckPrice({
                            discountedCheckPrice: e,
                          });
                          calculatePrices({
                            discountedCheckPrice: e,
                          });
                        }}
                        onBlur={(e) => {
                          const value = Number(
                            e.target.value.replace(/[^0-9]/g, '')
                          );
                          field.onChange(value);

                          setCheckPrice({
                            discountedCheckPrice: value,
                          });
                          calculatePrices({
                            discountedCheckPrice: value,
                          });
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col-12 sm:col-12 md:col-8 lg:col-8 mb-2">
                  <Controller
                    control={control}
                    name={'discountReason'}
                    defaultValue=""
                    rules={{
                      required:
                        _.get(currentValues, 'discountedCheckPrice') > 0 &&
                        '필수 입력항목입니다.',
                    }}
                    render={({ field }) => (
                      <>
                        <Grouped.TitledInputText
                          classNameProps={true}
                          id={field.name}
                          title="할인사유"
                          placeholder="할인 사유를 입력하세요."
                          {...field}
                        />
                        {getFormErrorMessage(field.name)}
                      </>
                    )}
                  />
                </div>
              </div>
            </Panel>
          </div>

          <div className="col-12">
            <Panel header="3. 보험료 정보">
              <div className="grid">
                <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                  <Controller
                    control={control}
                    name={'assuranceType'}
                    defaultValue="2"
                    rules={{
                      required: '필수 입력항목입니다.',
                    }}
                    render={({ field }) => (
                      <div className="bg-transparent flex flex-auto flex-wrap h-full align-items-center justify-content-start gap-3">
                        {[
                          { label: '자가 보증', value: '1' },
                          { label: '보험사 보증', value: '2' },
                        ].map((item, idx) => {
                          return (
                            <div key={`${field.name}_${idx}`}>
                              <div className="flex flex-auto align-items-center justify-content-start gap-1">
                                <RadioButton
                                  disabled
                                  inputId={`trb_${field.name}_${idx}`}
                                  name={field.name}
                                  value={item.value}
                                  checked={field.value === item.value}
                                />
                                <label
                                  className={item.labelColor}
                                  htmlFor={`trb_${field.name}_${idx}`}
                                >
                                  {item.label}
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  />
                </div>

                <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                  <Controller
                    control={control}
                    name={'totalInsurancePrice'}
                    defaultValue={0}
                    render={({ field }) => (
                      <Grouped.TitledInputNumber
                        id={field.name}
                        title={'보험료 (VAT포함)'}
                        {...field}
                        readOnly
                        suffix=" 원"
                        value={
                          currentValues?.insurancePrice +
                          currentValues?.insurancePriceVat
                        }
                      />
                    )}
                  />
                </div>
                <div className="col-12 sm:col-12 md:col-3 lg:col-3">
                  <Controller
                    control={control}
                    name={'insurancePricePaidBy'}
                    defaultValue=""
                    render={({ field }) => (
                      <Grouped.TitledDropdown
                        id={field.name}
                        title={'결제'}
                        {...field}
                        options={PAYMENT_METHOD}
                        onChange={({ value }) => {
                          field.onChange(value);
                        }}
                        disabled
                      />
                    )}
                  />
                </div>
              </div>
            </Panel>
          </div>

          <div className="col-12 my-2 py-0">
            <Panel header="4. 총 합계 정보">
              <table
                className="w-full h-full border-1 border-gray-300 border-round-sm"
                cellPadding="0"
                cellSpacing="0"
              >
                <thead />
                <tbody>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 border-bottom-1 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      총 합계 금액 (VAT 포함)
                    </th>
                    <td className="px-2 border-gray-300 border-bottom-1">
                      {CommonUtil.Unit.format(totalCheckPrice + totalInsurance)}{' '}
                      원
                    </td>
                  </tr>
                  <tr className="border-1 border-gray-300">
                    <th className="px-2 py-1 border-gray-300 bg-gray-100 sm:w-10rem md:w-15rem lg:w-20rem">
                      변경 후 총 합계 금액 (VAT 포함)
                    </th>
                    <td className="px-2 border-gray-300">
                      {CommonUtil.Unit.format(
                        currentValues.checkPrice +
                          currentValues.checkPriceVat -
                          currentValues.discountedCheckPrice +
                          currentValues.insurancePrice +
                          currentValues.insurancePriceVat
                      )}{' '}
                      원
                    </td>
                  </tr>
                </tbody>
              </table>
            </Panel>
          </div>
          <div className="button_container flex flex-auto justify-content-center gap-2 mt-2">
            <Button.Default
              type="button"
              icon="pi pi-save"
              label="변경사항 저장"
              disabled={!isPossible}
              onClick={() => {
                updatePayment();
              }}
            />
          </div>
        </div>
      </BlockUI>
    </Dialog>
  );
};

export default PaymentUpdateDialog;

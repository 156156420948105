import * as Diff from 'deep-object-diff';
import * as ExcelJS from 'exceljs-enhance';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';
import {
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
  YN,
} from '../../../constants/Constants';

import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import InsurancePriceSaveConfirmDialog from './InsurancePriceSaveConfirmDialog';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ServiceProvider } from '../../../services';
import { SplitButton } from 'primereact/splitbutton';
import { Tag } from 'primereact/tag';
import UserPosition from '../../../enums/UserPosition';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';

const defaultValues = _.transform(
  VEHICLE_TYPE_LEVEL3,
  (r, v, k) => {
    _.map(
      _.times(10, String),
      (i) => (r[`${v.lvl1}${v.lvl2}${v.value}_${i}`] = 0)
    );
    return r;
  },
  {}
);

const codeService = ServiceProvider.code;
const shopInsurancePriceService = ServiceProvider.shopInsurancePrice;

const ShopInsurancePriceDialog = ({
  userInfo,
  insurancePriceTable: {
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
    priceTableId = 0,
    isDeletable = 'N',
  },
  onHide = null,
}) => {
  const { myAssociationId, myEnterpriseId, myUserPosition } = useMemo(
    () => MyInfoUtil.unpack(userInfo),
    [userInfo]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });
  const currentValues = useWatch({ control });

  const [selectedAssociationId, setSelectedAssociationId] = useState(
    associationId || myAssociationId
  );
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(
    enterpriseId || myEnterpriseId
  );
  const [selectedBaseInsurancePriceTable, setSelectedBaseInsurancePriceTable] =
    useState(null);
  const [selectedShopId, setSelectedShopId] = useState(shopId);
  const [codes, setCodes] = useState({});

  const [insurancePriceTable, setInsurancePriceTable] = useState(null);
  const [actualApplicationRatio, setActualApplicationRatio] = useState(0);
  const [actualInsurancePriceTable, setActualInsurancePriceTable] = useState({
    ...defaultValues,
  });
  const [minStartDate, setMinStartDate] = useState(null);

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    delete: false,
  });
  const [loading, setLoading] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isSafeToReset, setIsSafeToReset] = useState(false);
  const [isSubmitDone, setIsSubmitDone] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);

      if (acceptedFiles[0]) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(acceptedFiles[0]);
        reader.onload = () => {
          const buffer = reader.result;

          workbook.xlsx.load(buffer).then((workbook) => {
            const worksheet =
              workbook.getWorksheet('보험료양식') ||
              workbook.getWorksheet('보험료테이블');

            if (worksheet) {
              let tableData = {};

              worksheet.eachRow((row, rowIndex) => {
                if (rowIndex > 2) {
                  // exceljs - row.values의 첫번째 데이터가 항상 null임
                  const [, type, detail] = row.values;
                  const dataKey = convertKey(type, detail);

                  for (let i = 3; i < row.values.length; i++) {
                    let price = row.values[i];
                    if (_.isObject(price)) price = price.result;

                    tableData[`${dataKey}_${i - 3}`] = _.toNumber(
                      `${_.isEmpty(_.toString(price)) ? 0 : price}`
                    );
                  }
                }
              });

              reset(tableData);
            } else {
              window.cerp.toast.warn(
                '보험료 테이블 양식 오류',
                '올바른 보험료 테이블 양식이 아닙니다.'
              );
            }
          });
        };
      }
    },
  });

  function convertKey(type, detail) {
    const [lvl1, lvl2] = _.split(type, '-');

    const lvl1Code = _.find(VEHICLE_TYPE_LEVEL1, { label: lvl1 })?.value;
    const lvl2Code = _.find(VEHICLE_TYPE_LEVEL2, { label: lvl2 })?.value;
    const lvl3Code = _.find(VEHICLE_TYPE_LEVEL3, { label: detail })?.value;

    return `${lvl1Code}${lvl2Code}${lvl3Code}`;
  }

  async function onSubmit(formData, confirmData) {
    setLoading(true);

    const baseInsurancePriceTableId = _.get(
      selectedBaseInsurancePriceTable,
      'codeData.priceTableId'
    );
    const insuranceCompanyCode = _.get(
      selectedBaseInsurancePriceTable,
      'codeData.insuranceCompany'
    );
    const body = {
      associationId: selectedAssociationId,
      enterpriseId: selectedEnterpriseId,
      shopId: selectedShopId,
      baseInsurancePriceTableId,
      insuranceCompanyCode,
      actualApplicationRatio,
      ...confirmData,
      tableData: JSON.stringify(formData),
      actualTableData: JSON.stringify(actualInsurancePriceTable),
    };

    const { data, error } = await shopInsurancePriceService.register(body);

    if (data) {
      window.cerp.toast.success(
        `보험료 테이블 등록 완료`,
        `보험료 테이블을 등록했습니다.`
      );

      const tableData = JSON.parse(data.tableData);
      setInsurancePriceTable({ ...data, tableData });
      reset(tableData);

      setIsSubmitDone(true);
    }

    if (error)
      window.cerp.dialog.error(
        `보험료 테이블 등록 실패`,
        `[${error?.code}] ${error?.message}`
      );

    setLoading(false);
  }

  async function checkStartDate() {
    setLoading(true);

    const selectedInsuranceCompanyCode = _.get(
      selectedBaseInsurancePriceTable,
      'codeData.insuranceCompany'
    );
    const { data } = await shopInsurancePriceService.checkStartDate(
      selectedShopId,
      selectedInsuranceCompanyCode
    );

    if (data) {
      const { minStartDate } = data;
      const current = dayjs();
      const resDate = dayjs(minStartDate);
      const diff = current.diff(resDate);

      if (diff >= 0) {
        const tomorrow = current
          // .add(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DD');
        setMinStartDate(tomorrow);
      } else {
        setMinStartDate(minStartDate);
      }
    }

    setLoading(false);
  }

  async function getAssociationCodes({ preSelectedAssociationId }) {
    const { data } = await codeService.associations();
    const associations = [...data];
    const association =
      (preSelectedAssociationId
        ? _.find(associations, { value: preSelectedAssociationId })
        : _.get(associations, 0)) || {};

    return [associations, _.get(association, 'value'), association];
  }

  async function getEnterpriseCodes({
    associationId = 0,
    preSelectedEnterpriseId,
  }) {
    if (associationId === 0) return [[], 0, {}];

    const { data } = await codeService.enterprises([associationId]);
    const enterprises = [...data];

    const enterprise =
      (preSelectedEnterpriseId
        ? _.find(enterprises, { value: preSelectedEnterpriseId })
        : _.get(enterprises, 0)) || {};
    return [enterprises, _.get(enterprise, 'value'), enterprise];
  }

  async function getShopCodes({ enterpriseId = 0, preSelectedShopId }) {
    if (enterpriseId === 0) return [[], 0, {}];

    const { data } = await codeService.shops([enterpriseId]);
    const shops = [...data];

    const shop =
      (preSelectedShopId
        ? _.find(shops, { value: preSelectedShopId })
        : _.get(shops, 0)) || {};
    return [shops, _.get(shop, 'value'), shop];
  }

  async function getInsuranceCodes() {
    const { data: insurances } = await codeService.insurances();
    const { value: insuranceCompanyCode = 0 } = insurances[0] || {};
    return [insurances, insuranceCompanyCode];
  }

  async function getBaseInsurancePriceTableCodes({
    enterpriseId = 0,
    preSelectedBasePriceTableId,
  }) {
    if (enterpriseId === 0) return [[], 0, null];
    const { data: baseInsurancePriceTables } =
      await codeService.baseInsurancePriceTables(enterpriseId);
    const baseInsurancePriceTable =
      (preSelectedBasePriceTableId
        ? _.find(baseInsurancePriceTables, {
            value: preSelectedBasePriceTableId,
          })
        : _.get(baseInsurancePriceTables, 0)) || null;
    return [
      baseInsurancePriceTables,
      _.get(baseInsurancePriceTable, 'value'),
      baseInsurancePriceTable,
    ];
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (priceTableId === 0) {
        const [[insurances], [associations, associationId]] = await Promise.all(
          [getInsuranceCodes(), getAssociationCodes({})]
        );
        const [enterprises, enterpriseId] = await getEnterpriseCodes({
          associationId,
        });
        const [
          [shops, shopId],
          [baseInsurancePriceTables, , baseInsurancePriceTable],
        ] = await Promise.all([
          getShopCodes({ enterpriseId }),
          getBaseInsurancePriceTableCodes({ enterpriseId }),
        ]);

        setCodes({
          insurances,
          associations,
          enterprises,
          shops,
          baseInsurancePriceTables,
        });
        setSelectedAssociationId(associationId);
        setSelectedEnterpriseId(enterpriseId);
        setSelectedShopId(shopId);
        setSelectedBaseInsurancePriceTable(baseInsurancePriceTable);
      } else {
        const { data, error } = await shopInsurancePriceService.getData(
          priceTableId
        );

        if (data) {
          const {
            associationId,
            enterpriseId,
            shopId,
            basePriceTableId,
            actualApplicationRatio,
          } = data;
          const tableData = JSON.parse(data.tableData);
          const actualTableData = JSON.parse(data.actualTableData);

          const [
            [insurances],
            [associations],
            [enterprises],
            [shops],
            [baseInsurancePriceTables, , baseInsurancePriceTable],
          ] = await Promise.all([
            getInsuranceCodes(),
            getAssociationCodes({ preSelectedAssociationId: associationId }),
            getEnterpriseCodes({
              associationId,
              preSelectedEnterpriseId: enterpriseId,
            }),
            getShopCodes({ enterpriseId, preSelectedShopId: shopId }),
            getBaseInsurancePriceTableCodes({
              enterpriseId,
              preSelectedBasePriceTableId: basePriceTableId,
            }),
          ]);

          setCodes({
            insurances,
            associations,
            enterprises,
            shops,
            baseInsurancePriceTables,
          });
          setSelectedAssociationId(associationId);
          setSelectedEnterpriseId(enterpriseId);
          setSelectedShopId(shopId);
          setSelectedBaseInsurancePriceTable(baseInsurancePriceTable);
          setActualApplicationRatio(actualApplicationRatio);
          setInsurancePriceTable({ ...data, tableData });
          setActualInsurancePriceTable(actualTableData);
        }

        if (error)
          window.cerp.dialog.error(
            '점검료 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
      }

      setLoading(false);
    })();
  }, [associationId, enterpriseId, priceTableId, reset]);

  useEffect(() => {
    if (insurancePriceTable !== null)
      reset(_.get(insurancePriceTable, 'tableData'));
  }, [insurancePriceTable, reset]);

  useEffect(() => {
    if (isSafeToReset) reset();
    return () => setIsSafeToReset(false);
  }, [isSafeToReset, reset]);

  useEffect(() => {
    if (selectedBaseInsurancePriceTable !== null) {
      const table = _.get(
        selectedBaseInsurancePriceTable,
        'codeData.actualPriceTable'
      );
      reset(JSON.parse(table));
      return;
    }
    reset(defaultValues);
  }, [reset, selectedBaseInsurancePriceTable]);

  useEffect(() => {
    setIsDataChanged(
      !_.isEmpty(Diff.diff(insurancePriceTable?.tableData, currentValues))
    );
  }, [insurancePriceTable, currentValues]);

  useEffect(() => {
    if (priceTableId === 0) {
      const ratio = actualApplicationRatio / 100;
      const applied = _.reduce(
        currentValues,
        (r, v, k) => {
          r[k] = Math.round((_.toNumber(v) * (1 + ratio)) / 100) * 100; // 백원단위절사
          return r;
        },
        {}
      );
      setActualInsurancePriceTable(applied);
    }
  }, [currentValues, actualApplicationRatio, priceTableId]);

  return (
    <Dialog
      modal
      maximizable
      visible
      style={{ width: '90vw' }}
      onHide={onHide}
      header="기본 보험료 테이블"
      footer={
        <div className="flex flex-auto align-items-start justify-content-start pt-4">
          <Button
            type="button"
            label="닫기"
            className="p-button-outlined"
            onClick={onHide}
          />
          {priceTableId === 0 && !isSubmitDone && (
            <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
              <ConfirmDialog
                visible={showConfirmPopup['reset']}
                header="확인"
                message="변경된 내용을 초기화 하시겠습니까?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="네"
                acceptIcon="pi pi-check"
                accept={() => setIsSafeToReset(true)}
                rejectLabel="아니오"
                reject={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: false }))
                }
                onHide={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: false }))
                }
              />
              <Button
                id="btn-reset"
                className="p-button-outlined"
                label="초기화"
                icon="pi pi-undo"
                autoFocus
                disabled={!isDataChanged}
                onClick={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, reset: true }))
                }
              />

              {showConfirmPopup['submit'] && (
                <InsurancePriceSaveConfirmDialog
                  isUpdate={insurancePriceTable !== null}
                  minStartDate={minStartDate}
                  onHide={(confirmData) => {
                    setShowConfirmPopup((ps) => ({ ...ps, submit: false }));
                    setMinStartDate(null);
                    if (confirmData)
                      handleSubmit((formData) =>
                        onSubmit(formData, confirmData)
                      )();
                  }}
                />
              )}
              <Button
                id="btn-save"
                label="저장"
                icon="pi pi-save"
                autoFocus
                tooltip="점검장 보험료 테이블을 등록하기 위해서는 점검법인, 점검장, 점검장 보험료 테이블이 모두 등록되어 있어야 합니다."
                tooltipOptions={{
                  position: 'bottom',
                  mouseTrack: true,
                  style: { width: '200px', wordBreak: 'break-word' },
                  showOnDisabled: true,
                }}
                disabled={
                  !isDataChanged ||
                  !(
                    selectedBaseInsurancePriceTable !== null &&
                    selectedAssociationId > 0 &&
                    selectedEnterpriseId > 0 &&
                    selectedShopId > 0
                  )
                }
                onClick={async () => {
                  await checkStartDate();
                  setShowConfirmPopup((ps) => ({ ...ps, submit: true }));
                }}
              />
            </div>
          )}

          {priceTableId > 0 && isDeletable === YN.YES && (
            <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
              <ConfirmDialog
                visible={showConfirmPopup['delete']}
                header="확인"
                message="보험료 테이블을 삭제하시겠습니까?"
                icon="pi pi-exclamation-triangle"
                acceptLabel="네"
                acceptIcon="pi pi-check"
                acceptClassName="p-button-danger"
                accept={async () => {
                  const { data, error } =
                    await shopInsurancePriceService.delete(priceTableId);
                  console.log(data, error);

                  if (data) {
                    window.cerp.toast.success(
                      `보험료 테이블 삭제 완료`,
                      `보험료 테이블을 삭제했습니다.`
                    );
                    onHide();
                  }

                  if (error)
                    window.cerp.dialog.error(
                      '보험료 테이블 삭제 실패',
                      `[${error?.code}] ${error?.message}`
                    );
                }}
                rejectLabel="아니오"
                reject={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                }
                onHide={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: false }))
                }
              />
              <Button
                type="button"
                label="삭제"
                icon="pi pi-trash"
                className="p-button-danger"
                onClick={() =>
                  setShowConfirmPopup((ps) => ({ ...ps, delete: true }))
                }
              />
            </div>
          )}
        </div>
      }
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="grid">
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="field mb-0">
              <label>소속협회</label>
              <Dropdown
                className="w-full"
                value={selectedAssociationId}
                options={codes.associations}
                onChange={async ({ value }) => {
                  const [enterprises, enterpriseId] = await getEnterpriseCodes({
                    associationId: value,
                  });
                  const [shops, shopId] = await getShopCodes({ enterpriseId });
                  const [baseInsurancePriceTables, , baseInsurancePriceTable] =
                    await getBaseInsurancePriceTableCodes({
                      enterpriseId,
                    });
                  setCodes((ps) => ({
                    ...ps,
                    enterprises,
                    shops,
                    baseInsurancePriceTables,
                  }));
                  setSelectedAssociationId(value);
                  setSelectedEnterpriseId(enterpriseId);
                  setSelectedBaseInsurancePriceTable(baseInsurancePriceTable);
                  setSelectedShopId(shopId);
                }}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                filter
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '협회가 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="field mb-0">
              <label>소속점검법인</label>
              <Dropdown
                className="w-full"
                value={selectedEnterpriseId}
                options={codes.enterprises}
                onChange={async ({ value }) => {
                  const [shops, shopId] = await getShopCodes({
                    enterpriseId: value,
                  });
                  const [baseInsurancePriceTables, , baseInsurancePriceTable] =
                    await getBaseInsurancePriceTableCodes({
                      enterpriseId: value,
                    });

                  setCodes((ps) => ({
                    ...ps,
                    shops,
                    baseInsurancePriceTables,
                  }));
                  setSelectedEnterpriseId(value);
                  setSelectedShopId(shopId);
                  setSelectedBaseInsurancePriceTable(baseInsurancePriceTable);
                }}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                filter
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '점검법인이 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-6">
            <div className="field mb-0">
              <label>점검업체 보험료 테이블</label>
              <Dropdown
                className="w-full"
                value={_.get(selectedBaseInsurancePriceTable, 'value')}
                options={codes.baseInsurancePriceTables}
                onChange={({ value }) => {
                  const priceTable = _.find(codes.baseInsurancePriceTables, {
                    value,
                  });
                  setSelectedBaseInsurancePriceTable(priceTable);
                }}
                valueTemplate={(option, props) => {
                  if (option) {
                    const {
                      label,
                      codeData: { insuranceCompanyName },
                    } = option;
                    return (
                      <div className="w-full flex flex-row align-items-center justify-content-start gap-2">
                        <Tag value={insuranceCompanyName} />
                        <div className="w-full flex flex-column">
                          <span>{label}</span>
                        </div>
                      </div>
                    );
                  }

                  return <span>{props.placeholder}</span>;
                }}
                itemTemplate={({
                  label,
                  value,
                  codeData: {
                    insuranceCompany,
                    insuranceCompanyName,
                    startDate,
                  },
                }) => (
                  <div className="w-full flex flex-row align-items-center justify-content-start gap-2">
                    <Tag value={insuranceCompanyName} />
                    <div className="w-full flex flex-column">
                      <span>{label}</span>
                      <div className="text-sm">(적용일자 : {startDate} ~)</div>
                    </div>
                  </div>
                )}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '테이블이 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-6 lg:col-3">
            <div className="field mb-0">
              <label>대상점검장</label>
              <Dropdown
                className="w-full"
                value={selectedShopId}
                options={codes.shops}
                onChange={async ({ value }) => {
                  setSelectedShopId(value);
                }}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
                filter
                placeholder={
                  loading ? (
                    <>
                      <i className="pi pi-spin pi-spinner m-0 mr-2" />
                      조회하고 있습니다...
                    </>
                  ) : (
                    '점검장이 없습니다.'
                  )
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-3 lg:col-1">
            <div className="field mb-0">
              <label>실제적용율</label>
              <InputNumber
                className="w-full"
                inputClassName="w-full text-right"
                suffix=" %"
                mode="decimal"
                minFractionDigits={2}
                maxFractionDigits={4}
                value={actualApplicationRatio}
                onFocus={(e) =>
                  priceTableId === 0 &&
                  _.defaultTo(e.target.value, '') !== '' &&
                  e.target.select()
                }
                onValueChange={(e) => setActualApplicationRatio(e.value)}
                disabled={
                  !_.includes(
                    [UserPosition.Erp, UserPosition.Association],
                    myUserPosition
                  ) ||
                  priceTableId > 0 ||
                  isSubmitDone
                }
              />
            </div>
          </div>
          <div className="col-12 sm:col-3 lg:col-3">
            <div className="w-full h-full flex flex-row align-items-end justify-content-start">
              {priceTableId === 0 && !isSubmitDone ? (
                <>
                  <div
                    {...getRootProps({
                      className: '',
                      style: { wordBreak: 'keep-all' },
                    })}
                  >
                    <input {...getInputProps()} />
                  </div>
                  <SplitButton
                    className="w-full"
                    buttonClassName="w-full p-button-outlined"
                    menuButtonClassName="p-button-outlined"
                    label="엑셀불러오기"
                    icon="pi pi-folder-open"
                    onClick={open}
                    model={[
                      {
                        label: '양식다운로드',
                        icon: 'pi pi-file-excel',
                        command: async (e) => {
                          const workbook = new ExcelJS.Workbook();
                          const worksheet = workbook.addWorksheet('보험료양식');

                          worksheet.getRow(2).values = [
                            '',
                            '',
                            '~2만 이하',
                            '~4만 이하',
                            '~6만 이하',
                            '~8만 이하',
                            '~10만 이하',
                            '~12만 이하',
                            '~14만 이하',
                            '~16만 이하',
                            '~18만 이하',
                            '~20만 이하',
                          ];

                          worksheet.columns = [
                            { key: 'type', width: 12 },
                            { key: 'detail', width: 36 },
                            { header: '~2만 이하', key: 'dist_0', width: 10 },
                            { header: '~4만 이하', key: 'dist_1', width: 10 },
                            { header: '~6만 이하', key: 'dist_2', width: 10 },
                            { header: '~8만 이하', key: 'dist_3', width: 10 },
                            { header: '~10만 이하', key: 'dist_4', width: 10 },
                            { header: '~12만 이하', key: 'dist_5', width: 10 },
                            { header: '~14만 이하', key: 'dist_6', width: 10 },
                            { header: '~16만 이하', key: 'dist_7', width: 10 },
                            { header: '~18만 이하', key: 'dist_8', width: 10 },
                            { header: '~20만 이하', key: 'dist_9', width: 10 },
                          ];

                          _.forEach(VEHICLE_TYPE_LEVEL1, (level1) => {
                            _.forEach(VEHICLE_TYPE_LEVEL2, (level2) => {
                              _.forEach(
                                _.filter(VEHICLE_TYPE_LEVEL3, {
                                  lvl1: level1.value,
                                  lvl2: level2.value,
                                }),
                                (level3) => {
                                  let rowData = {
                                    type: `${level1.label}-${level2.label}`,
                                    detail: level3.label,
                                  };
                                  _.map(
                                    _.times(10, String),
                                    (i) => (rowData[`dist_${i}`] = '')
                                  );
                                  worksheet.addRow(rowData);
                                }
                              );
                            });
                          });

                          worksheet.mergeCells('A1', 'B2');
                          worksheet.getCell('A1').value = '구분 (단위 : 원)';
                          worksheet.getCell('A1').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };

                          worksheet.mergeCells('C1', 'L1');
                          worksheet.getCell('C1').value = '주행거리 (km)';
                          worksheet.getCell('C1').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };

                          worksheet.getCell('C2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('D2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('E2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('F2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('G2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('H2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('I2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('J2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('K2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };
                          worksheet.getCell('L2').alignment = {
                            vertical: 'middle',
                            horizontal: 'center',
                          };

                          const mimeType = {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                          };
                          const buffer = await workbook.xlsx.writeBuffer();
                          const blob = new Blob([buffer], mimeType);
                          saveAs(blob, '보험료양식.xlsx');
                        },
                      },
                    ]}
                  />
                </>
              ) : (
                <Button
                  icon="pi pi-download"
                  label="보험료 테이블 내려받기"
                  className="w-full p-button-outlined"
                  onClick={async (e) => {
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('보험료테이블');

                    worksheet.getRow(2).values = [
                      '',
                      '',
                      '~2만 이하',
                      '~4만 이하',
                      '~6만 이하',
                      '~8만 이하',
                      '~10만 이하',
                      '~12만 이하',
                      '~14만 이하',
                      '~16만 이하',
                      '~18만 이하',
                      '~20만 이하',
                    ];

                    worksheet.columns = [
                      { key: 'type', width: 12 },
                      { key: 'detail', width: 36 },
                      { header: '~2만 이하', key: 'dist_0', width: 10 },
                      { header: '~4만 이하', key: 'dist_1', width: 10 },
                      { header: '~6만 이하', key: 'dist_2', width: 10 },
                      { header: '~8만 이하', key: 'dist_3', width: 10 },
                      { header: '~10만 이하', key: 'dist_4', width: 10 },
                      { header: '~12만 이하', key: 'dist_5', width: 10 },
                      { header: '~14만 이하', key: 'dist_6', width: 10 },
                      { header: '~16만 이하', key: 'dist_7', width: 10 },
                      { header: '~18만 이하', key: 'dist_8', width: 10 },
                      { header: '~20만 이하', key: 'dist_9', width: 10 },
                    ];

                    _.forEach(VEHICLE_TYPE_LEVEL1, (level1) => {
                      _.forEach(VEHICLE_TYPE_LEVEL2, (level2) => {
                        _.forEach(
                          _.filter(VEHICLE_TYPE_LEVEL3, {
                            lvl1: level1.value,
                            lvl2: level2.value,
                          }),
                          (level3) => {
                            let rowData = {
                              type: `${level1.label}-${level2.label}`,
                              detail: level3.label,
                            };
                            let rowActualData = {
                              type: '',
                              detail: '',
                            };
                            _.map(_.times(10, String), (i) => {
                              rowData[`dist_${i}`] =
                                insurancePriceTable.tableData[
                                  `${level1.value}${level2.value}${level3.value}_${i}`
                                ];
                              rowActualData[`dist_${i}`] =
                                actualInsurancePriceTable[
                                  `${level1.value}${level2.value}${level3.value}_${i}`
                                ];
                            });
                            worksheet.addRow(rowData);
                            worksheet.addRow(rowActualData);
                          }
                        );
                      });
                    });

                    for (
                      let i = 3;
                      i < (VEHICLE_TYPE_LEVEL3.length + 1) * 2;
                      i = i + 2
                    ) {
                      worksheet.mergeCells(`A${i}`, `A${i + 1}`);
                      worksheet.getCell(`A${i}`).alignment = {
                        vertical: 'middle',
                        horizontal: 'center',
                      };
                      worksheet.mergeCells(`B${i}`, `B${i + 1}`);
                      worksheet.getCell(`B${i}`).alignment = {
                        vertical: 'middle',
                        horizontal: 'center',
                      };
                    }

                    worksheet.mergeCells('A1', 'B2');
                    worksheet.getCell('A1').value = '구분 (단위 : 원)';
                    worksheet.getCell('A1').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };

                    worksheet.mergeCells('C1', 'L1');
                    worksheet.getCell('C1').value = '주행거리 (km)';
                    worksheet.getCell('C1').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };

                    worksheet.getCell('C2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('D2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('E2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('F2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('G2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('H2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('I2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('J2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('K2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };
                    worksheet.getCell('L2').alignment = {
                      vertical: 'middle',
                      horizontal: 'center',
                    };

                    const mimeType = {
                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    };
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], mimeType);
                    saveAs(blob, '점검장보험료테이블.xlsx');
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <form>
          <table
            className="w-full border-1 border-gray-400"
            cellSpacing="0"
            cellPadding="0"
            style={{ borderCollapse: 'collapse' }}
          >
            <thead />
            <tbody>
              <tr>
                <th
                  colSpan="3"
                  rowSpan="2"
                  className="text-center bg-gray-200 p-2 border-1 border-gray-400"
                >
                  구분 (단위 : 원)
                </th>
                <th
                  colSpan="10"
                  className="text-center bg-gray-200 p-2 border-1 border-gray-400"
                >
                  주행거리(km)
                </th>
              </tr>
              <tr>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 2만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 4만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 6만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 8만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 10만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 12만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 14만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 16만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 18만 이하
                </th>
                <th className="text-center bg-gray-200 p-2 border-1 border-gray-400">
                  ~ 20만 이하
                </th>
              </tr>

              <tr>
                <th
                  rowSpan="18"
                  style={{ width: 50 }}
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  국산
                </th>
                <th
                  rowSpan="6"
                  style={{ width: 50 }}
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  승용
                </th>
                <th
                  style={{ width: 180 }}
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  초소형/소형A
                  <br />
                  (1000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1SA_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1SA_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  소형B
                  <br />
                  (1600cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1SB_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1SB_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  중형
                  <br />
                  (2000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1MD_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1MD_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  대형
                  <br />
                  (2000cc 초과)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1LG_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1LG_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  다인승
                  <br />
                  (1,2종 / 7~10인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1MP_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1MP_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D1EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D1EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="4"
                  className="text-center bg-gray-200 p-2 border-1 border-gray-400"
                >
                  승합
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  1종
                  <br />
                  (26~35인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D21C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D21C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  2~3종
                  <br />
                  (11~25인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D22C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D22C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경승합
                  <br />
                  (10인 이하 & 1000cc 미만)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D2LM_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D2LM_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D2EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D2EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="5"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  화물
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  2종
                  <br />
                  (2.5톤 초과 5톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D32C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D32C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      onFocus={(e) =>
                        priceTableId === 0 &&
                        _.defaultTo(e.target.value, '') !== '' &&
                        e.target.select()
                      }
                      onValueChange={({ value }) => {
                        const ratio = actualApplicationRatio / 100;
                        let v = _.defaultTo(
                          value,
                          Math.round(
                            (_.get(currentValues, `D32C_${i}`) * (1 + ratio)) /
                              100
                          ) * 100
                        );
                        setActualInsurancePriceTable((ps) => ({
                          ...ps,
                          [`D32C_${i}`]: v,
                        }));
                      }}
                      readOnly={priceTableId > 0}
                      disabled={_.get(currentValues, `D32C_${i}`) === 0}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  3종
                  <br />
                  (1톤 초과 2.5톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D33C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D33C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      onFocus={(e) =>
                        priceTableId === 0 &&
                        _.defaultTo(e.target.value, '') !== '' &&
                        e.target.select()
                      }
                      onValueChange={({ value }) => {
                        const ratio = actualApplicationRatio / 100;
                        let v = _.defaultTo(
                          value,
                          Math.round(
                            (_.get(currentValues, `D33C_${i}`) * (1 + ratio)) /
                              100
                          ) * 100
                        );
                        setActualInsurancePriceTable((ps) => ({
                          ...ps,
                          [`D33C_${i}`]: v,
                        }));
                      }}
                      readOnly={priceTableId > 0}
                      disabled={_.get(currentValues, `D33C_${i}`) === 0}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  4종
                  <br />
                  (1톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D34C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D34C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경화물
                  <br />
                  (1000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D3LF_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D3LF_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D3EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D3EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="3"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  특수
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  4종
                  <br />
                  (1톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D44C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D44C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경화물
                  <br />
                  (1000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D4LF_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D4LF_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`D4EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`D4EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="17"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  외산
                </th>
                <th
                  rowSpan="5"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  승용
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  초소형/소형A, B<br />
                  (1600cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F1SM_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F1SM_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  중형
                  <br />
                  (2000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F1MD_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F1MD_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  대형
                  <br />
                  (2000cc 초과)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F1LG_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F1LG_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  다인승
                  <br />
                  (1,2종 / 7~10인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F1MP_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F1MP_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F1EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F1EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="4"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  승합
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  1종
                  <br />
                  (26~35인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F21C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F21C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  2~3종
                  <br />
                  (11~25인승 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F22C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F22C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경승합
                  <br />
                  (10인 이하 & 1000cc 미만)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F2LM_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F2LM_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F2EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F2EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="5"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  화물
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  2종
                  <br />
                  (2.5톤 초과 5톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F32C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F32C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      onFocus={(e) =>
                        priceTableId === 0 &&
                        _.defaultTo(e.target.value, '') !== '' &&
                        e.target.select()
                      }
                      onValueChange={({ value }) => {
                        const ratio = actualApplicationRatio / 100;
                        let v = _.defaultTo(
                          value,
                          Math.round(
                            (_.get(currentValues, `F32C_${i}`) * (1 + ratio)) /
                              100
                          ) * 100
                        );
                        setActualInsurancePriceTable((ps) => ({
                          ...ps,
                          [`F32C_${i}`]: v,
                        }));
                      }}
                      readOnly={priceTableId > 0}
                      disabled={_.get(currentValues, `F32C_${i}`) === 0}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  3종
                  <br />
                  (1톤 초과 2.5톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F33C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F33C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      onFocus={(e) =>
                        priceTableId === 0 &&
                        _.defaultTo(e.target.value, '') !== '' &&
                        e.target.select()
                      }
                      onValueChange={({ value }) => {
                        const ratio = actualApplicationRatio / 100;
                        let v = _.defaultTo(
                          value,
                          Math.round(
                            (_.get(currentValues, `F33C_${i}`) * (1 + ratio)) /
                              100
                          ) * 100
                        );
                        setActualInsurancePriceTable((ps) => ({
                          ...ps,
                          [`F33C_${i}`]: v,
                        }));
                      }}
                      readOnly={priceTableId > 0}
                      disabled={_.get(currentValues, `F33C_${i}`) === 0}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  4종
                  <br />
                  (1톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F34C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F34C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경화물
                  <br />
                  (1000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F3LF_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F3LF_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F3EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F3EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th
                  rowSpan="3"
                  className="text-center bg-gray-200 border-1 border-gray-400"
                >
                  특수
                </th>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  4종
                  <br />
                  (1톤 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F44C_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F44C_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  경화물
                  <br />
                  (1000cc 이하)
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F4LF_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F4LF_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th className="text-center bg-gray-200 border-1 border-gray-400">
                  전기차 / 수소차
                </th>
                {_.map(_.times(10, String), (i) => (
                  <td key={`td_${i}`} className="p-0 border-1 border-gray-400">
                    <Controller
                      control={control}
                      name={`F4EH_${i}`}
                      rules={{
                        validate: (v) => v >= 0 || '0보다 큰 값을 입력하세요.',
                      }}
                      render={({ field }) => (
                        <InputNumber
                          id={field.name}
                          {..._.omit(field, ['ref'])}
                          inputRef={field.ref}
                          inputClassName={classNames(
                            'w-full border-0 font-medium text-right px-1 py-0',
                            { 'p-invalid': _.get(errors, field.name)?.message }
                          )}
                          readOnly
                          suffix=" 원"
                        />
                      )}
                    />
                    <InputNumber
                      value={actualInsurancePriceTable[`F4EH_${i}`]}
                      inputClassName="w-full border-0 text-right px-1 py-0 text-red-300 font-bold"
                      suffix=" 원"
                      readOnly={true}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </form>
      </BlockUI>
    </Dialog>
  );
};

export default ShopInsurancePriceDialog;

import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { CodeUtil } from '../../../utils/CodeUtil';
import { CommonUtil } from '../../../utils/commonUtil';
import { Dropdown } from 'primereact/dropdown';
import FormUtil from '../../../utils/formUtil';
import { InputText } from 'primereact/inputtext';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import Postcode from './../../Common/Postcode';
import { ServiceProvider } from '../../../services';
import UserPosition from './../../../enums/UserPosition';
import _ from 'lodash';
import { myInfoSelector } from '../../../recoil/selectors';
import { useFormValid } from '../../../hooks/useFormValid';
import { useRecoilValueLoadable } from 'recoil';

const formField = [
  {
    code: 'associationId',
    title: '소속협회',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
  },
  {
    code: 'enterpriseId',
    title: '소속점검법인',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
  },
  {
    code: 'businessRegistrationNumber',
    title: '사업자등록번호',
    type: 'biz',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'shopDetailInfo',
  },
  {
    code: 'shopCode',
    title: '점검장 코드',
    type: 'code',
    defaultValue: '',
    required: true,
    pattern: false,
  },
  {
    code: 'shopName',
    title: '점검장명',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'shopBasicInfo',
  },
  {
    code: 'fax',
    title: '팩스',
    type: 'fax',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'shopDetailInfo',
  },
  {
    code: 'mainPhone',
    title: '대표전화',
    type: 'phone',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'shopDetailInfo',
  },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: false,
    pattern: true,
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'representative',
    title: '대표자명',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'cellphone',
    title: '휴대전화',
    type: 'cellphone',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'email',
    title: '이메일',
    type: 'email',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'businessType',
    title: '업태',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: false,
    category: 'businessInfo',
  },
  {
    code: 'businessItem',
    title: '업종',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: false,
    category: 'businessInfo',
  },
  {
    code: 'statementNumberPrefix',
    title: '성능점검구분번호prefix',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
  },
  {
    code: 'statementNumberAffix',
    title: '성능점검구분번호affix',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: false,
  },
  {
    code: 'insuranceCode',
    title: '보험사 코드',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: false,
    category: 'insurance',
  },
  {
    code: 'insurancePolicyNumber',
    title: '보험증권번호',
    type: 'text',
    defaultValue: '',
    required: false,
    pattern: false,
    category: 'insurance',
  },
  {
    code: 'useYn',
    title: '1. 점검장 기본 정보',
    type: 'boolean',
    defaultValue: 'Y',
    required: true,
    pattern: false,
  },
];

const associationService = ServiceProvider.association;
const shopService = ServiceProvider.shop;
const codeService = ServiceProvider.code;

export const ShopDialog = ({
  userInfo,
  shopId = 0,
  onHide = null,
  getList,
  params,
}) => {
  const { myAssociationId, myEnterpriseId, myUserPosition } = useMemo(
    () => MyInfoUtil.unpack(userInfo),
    [userInfo]
  );
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};

    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const { control, handleSubmit, reset, watch, trigger, setValue, getValues } =
    useForm({
      defaultValues: getDefaultValues(formField),
      reValidateMode: 'onSubmit',
    });
  const currentValues = useWatch({ control });

  const isModified = shopId > 0;

  const { isFormComplete } = useFormValid(watch(), formField, isModified);

  const [codes, setCodes] = useState({});
  const [shopData, setShopData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    shopCode: false,
    loginId: false,
  });
  const [needReset, setNeedReset] = useState(false);

  const [isPostcodeOpen, setIsPostcodeOpen] = useState(false);

  const getFilteredInputData = (formField, category) => {
    return formField.filter((item) => item.category === category);
  };

  async function orgDropdownHandler(value, field) {
    // 1. input value update -> field update
    field.onChange(value);

    // 2. field type에 따라 로직 분리
    // 2-2. enterprises면 값만 업데이트하고 끝
    if (field.name === 'enterpriseId') {
      setValue(`${field.name}`, value);
      return;
    }

    if (myInfoLoadable.state === 'hasValue') {
      const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

      const [enterprises, enterpriseId] = await CodeUtil.getEnterpriseCodes(
        myInfo,
        {
          [field.name]: value,
        }
      );
      const [affixes, affix] = await getAvailableStatementNumberAffixes({
        [field.name]: value,
      });

      const [, , association] = await CodeUtil.getAssociationCodes(myInfo, {
        preSelectedAssociationId: value,
      });
      const prefix = association.codeData.statementNumberPrefix;

      setCodes((ps) => ({
        ...ps,
        enterprises,
        affixes,
      }));
      setValue('enterpriseId', enterpriseId);
      setValue('statementNumberAffix', affix);
      setValue('statementNumberPrefix', prefix);
      setValue(`${field.name}`, value);
    }
  }

  async function getAssociationCodes({ preSelectedAssociationId }) {
    // 인증 타지 않는 code 필요함
    const { data } = await codeService.associations();
    const associations = preSelectedAssociationId
      ? [_.find(data, { value: preSelectedAssociationId })]
      : [{ value: '', label: '전체협회' }, ...data];
    const association = preSelectedAssociationId
      ? _.find(associations, { value: preSelectedAssociationId })
      : _.get(associations, 0) || {};

    return [associations, _.get(association, 'value'), association];
  }

  async function getEnterpriseCodes({
    isParentEmpty = false,
    associationId,
    preSelectedEnterpriseId,
  }) {
    // 인증 타지 않는 code 필요함
    if (isParentEmpty) return [[], 0, {}];

    const { data } = await codeService.enterprises([associationId]);
    const enterprises = [{ value: '', label: '전체점검법인' }, ...data];
    const enterprise = preSelectedEnterpriseId
      ? _.find(enterprises, { value: preSelectedEnterpriseId })
      : _.get(enterprises, 0) || {};

    return [enterprises, _.get(enterprise, 'value'), enterprise];
  }

  async function getInsuranceCodes({ insuranceCode = '' } = {}) {
    const { data: insurances } = await codeService.insurances();
    const insurance =
      (insuranceCode !== ''
        ? _.find(insurances, { value: insuranceCode })
        : _.get(insurances, 0)) || {};
    return [insurances, _.get(insurance, 'value'), insuranceCode];
  }

  async function getAvailableStatementNumberPrefixes({ preSelectedPrefix }) {
    const prefixes = [];

    if (!preSelectedPrefix) {
      const { data } =
        await associationService.getAvailableStatementNumberPrefixes();
      prefixes.push(...data);
    }

    const prefix = preSelectedPrefix
      ? { value: preSelectedPrefix }
      : _.get(prefixes, _.findIndex(prefixes, { isOccupied: false }));
    return [prefixes, _.get(prefix, 'value'), prefix];
  }

  async function getAvailableStatementNumberAffixes({
    associationId = 0,
    selectedPrefix,
    preSelectedAffix,
  }) {
    if (associationId === 0) return [[], '', {}];

    const { data: affixes } =
      await shopService.getAvailableStatementNumberAffixes(
        associationId,
        selectedPrefix
      );
    const affix = preSelectedAffix
      ? _.find(affixes, { value: preSelectedAffix })
      : _.get(affixes, _.findIndex(affixes, { isOccupied: false }));
    return [affixes, _.get(affix, 'value'), affix];
  }

  function fillEmptyField(data) {
    const copy = { ...data };
    for (let field in copy) {
      copy[field] = copy[field] ?? '';
    }

    return copy;
  }

  function setFormattedData(data) {
    const formatter = {
      businessRegistrationNumber: 'biz',
      mainPhone: 'phone',
      fax: 'fax',
      cellphone: 'cellphone',
    };

    for (let field in data) {
      if (formatter[field] && data[field]) {
        setValue(
          field,
          CommonUtil.Formatter[formatter[field]](_.get(data, field))
        );
      }
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        if (!isModified) {
          const [associations, associationId, association] =
            await getAssociationCodes({
              preSelectedAssociationId: myAssociationId,
            });
          const [enterprises] = await getEnterpriseCodes({
            isParentEmpty: _.isEmpty(associations),
            associationId: associationId,
          });
          const [insurances, insurance] = await getInsuranceCodes();

          setCodes((ps) => ({ ...ps, associations, enterprises, insurances }));
          setValue('associationId', myAssociationId ?? '');
          setValue('enterpriseId', myEnterpriseId ?? '');
          setValue('insuranceCode', insurance);

          if (associationId) {
            const [prefixes, prefix] =
              await getAvailableStatementNumberPrefixes({
                preSelectedPrefix: _.get(
                  association,
                  'codeData.statementNumberPrefix'
                ),
              });
            const [affixes, affix] = await getAvailableStatementNumberAffixes({
              associationId,
            });
            setCodes((ps) => ({ ...ps, prefixes, affixes }));
            setValue('statementNumberPrefix', prefix);
            setValue('statementNumberAffix', affix);
          }
        } else {
          try {
            const { data } = await shopService.getData(shopId);

            const {
              associationId,
              enterpriseId,
              statementNumberPrefix,
              statementNumberAffix,
              insuranceCode,
            } = data;

            const [[associations], [enterprises], [prefixes], [affixes]] =
              await Promise.all([
                CodeUtil.getAssociationCodes(myInfo, {
                  preSelectedAssociationId: associationId,
                }),
                CodeUtil.getEnterpriseCodes(myInfo, {
                  isParentEmpty: false,
                  associationId: myAssociationId,
                  preSelectedEnterpriseId: enterpriseId,
                }),
                getAvailableStatementNumberPrefixes({
                  preSelectedPrefix: statementNumberPrefix,
                }),
                getAvailableStatementNumberAffixes({
                  associationId,
                  preSelectedAffix: statementNumberAffix,
                }),
              ]);

            const [insurances] = await getInsuranceCodes({
              insuranceCode,
            });

            setCodes((ps) => ({
              ...ps,
              associations,
              enterprises,
              insurances,
              prefixes,
              affixes,
            }));

            setShopData(data);
            setFormattedData(data);
          } catch (error) {
            console.log(error);
            window.cerp.dialog.error(
              '점검장 정보 조회 실패',
              `[${error?.code}] ${error?.message}`
            );
          }
        }
      }

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, reset, setValue, myAssociationId, myEnterpriseId, isModified]);

  useEffect(() => {
    if (needReset) {
      setIsAvailable({ shopCode: false, loginId: false });
      reset(getDefaultValues(formField));

      setNeedReset(false);
    }
  }, [getDefaultValues, needReset, reset]);

  useEffect(() => {
    if (shopData !== null) {
      setIsAvailable({ shopCode: true, loginId: true });
      reset(fillEmptyField(shopData));
    }
  }, [shopData, reset]);

  return (
    <Modal.Form
      title={'점검장'}
      childDataName={'사용자'}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={async () => {
        try {
          const {
            data: { shop },
          } = await shopService.delete(shopId);
          window.cerp.toast.success(
            '점검장 삭제 완료',
            `[${shop.shopName}] 점검장이 삭제되었습니다.`
          );
          onHide();
          getList(
            params.searchConditions,
            params.lazyParams.page,
            params.lazyParams.rows
          );
        } catch (error) {
          window.cerp.dialog.error(
            '점검장 삭제 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }}
      onSubmitConfirm={handleSubmit(async (data) => {
        const biz = _.get(data, 'businessRegistrationNumber');
        const affix = _.get(data, 'statementNumberAffix');
        const formattedData = {
          ...data,
          businessRegistrationNumber: `${biz}-${affix}`,
        };
        const res = await FormUtil.Async.onSubmit(
          { formData: formattedData },
          isModified,
          'shop',
          '점검장',
          setNeedReset
        );
        if (res) {
          getList(
            params.searchConditions,
            params.lazyParams.page,
            params.lazyParams.rows
          );
        }
      })}
      saveBtnEnable={
        !(isAvailable.shopCode && isAvailable.loginId && isFormComplete)
      }
      deleteBtnVisible={CommonUtil.Func.checkUserAuth(userInfo, 'DELETE')}
    >
      <form autoComplete="off">
        <Panel
          headerTemplate={
            <div className="p-panel-header">
              {isModified &&
              CommonUtil.Func.checkUserAuth(userInfo, 'DISABLED') ? (
                <Controlled.InputSwitch
                  control={control}
                  watch={watch}
                  inputData={{
                    inputLabel: formField.filter(
                      (item) => item.code === 'useYn'
                    )[0].title,
                    toggleLabel: {
                      trueLabel: '사용',
                      falseLabel: '미사용',
                    },
                    dataLabel: 'useYn',
                  }}
                  styleClass={'font-semibold'}
                />
              ) : (
                <span className="font-semibold">1. 점검장 기본 정보</span>
              )}
            </div>
          }
        >
          <div className="grid">
            {[
              {
                code: 'associationId',
                title: '소속협회',
                dataKey: 'associations',
                placeholder: '협회없음',
                required: true,
              },
              {
                code: 'enterpriseId',
                title: '소속점검법인',
                dataKey: 'enterprises',
                placeholder: '점검법인없음',
                required: true,
              },
            ].map((item, idx) => (
              <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                <Controller
                  control={control}
                  name={item.code}
                  defaultValue={0}
                  rules={{
                    required: item.required ? '필수 입력항목입니다.' : false,
                    validate: (v) => v > 0 || '선택된 값이 없습니다.',
                  }}
                  render={({ field, fieldState, formState }) => (
                    <Titled.Dropdown
                      id={field.name}
                      {...item}
                      {...field}
                      options={codes[item.dataKey]}
                      error={formState.errors[field.name]}
                      placeholder={
                        loading ? (
                          <>
                            <i className="pi pi-spin pi-spinner m-0 mr-2" />
                            조회하고 있습니다...
                          </>
                        ) : (
                          item.placeholder
                        )
                      }
                      onChange={async (v) => orgDropdownHandler(v, field)}
                      disabled={
                        !(
                          (item.dataKey === 'associations' &&
                            _.includes([UserPosition.Erp], myUserPosition)) ||
                          (item.dataKey === 'enterprises' &&
                            _.includes(
                              [UserPosition.Erp, UserPosition.Association],
                              myUserPosition
                            ))
                        ) || !_.isEmpty(shopData)
                      }
                    />
                  )}
                />
              </div>
            ))}
            <div className="col-12 sm:col-6 lg:col-3">
              <Controlled.ValidateInputText
                id={shopId}
                control={control}
                trigger={trigger}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'shopCode'
                  )[0].title,
                  dataLabel: 'shopCode',
                }}
                inputConfig={{
                  minLength: 3,
                  maxLength: 3,
                  placeholder: '영문, 숫자 조합 (예시. X99)',
                }}
                rules={{
                  required: true,
                  pattern: {
                    value: CommonUtil.Pattern['enterpriseCode'],
                    message: '영문, 숫자 조합 (예시. X99)',
                  },
                  minLength: {
                    value: 3,
                    message: '코드는 3자리로 구성됩니다.',
                  },
                  maxLength: {
                    value: 3,
                    message: '코드는 3자리로 구성됩니다.',
                  },
                }}
              />
            </div>

            {getFilteredInputData(formField, 'shopBasicInfo').map(
              (item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}
            {getFilteredInputData(formField, 'shopDetailInfo').map(
              (item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}
            <div className="col-12 sm:col-6 lg:col-3">
              <div className="field m-0">
                <label>
                  <i className="text-red-400 pi pi-check mr-1" />
                  성능점검구분번호
                </label>
                <div className="p-inputgroup">
                  <Controller
                    control={control}
                    name="statementNumberPrefix"
                    defaultValue=""
                    rules={{
                      required: '필수 입력항목입니다.',
                    }}
                    render={({ field, fieldState, formState }) => (
                      <InputText id={field.name} {...field} readOnly disabled />
                    )}
                  />
                  <Controller
                    control={control}
                    name="statementNumberAffix"
                    defaultValue=""
                    rules={{
                      required: '필수 입력항목입니다.',
                      pattern: {
                        value: CommonUtil.Pattern['statementNumber'],
                        message: '유효하지 않은 포맷입니다.',
                      },
                    }}
                    render={({ field, fieldState, formState }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        options={_.get(codes, 'affixes')}
                        optionDisabled={(opt) =>
                          _.get(opt, 'isOccupied') &&
                          _.get(opt, 'shopId') !==
                            _.get(currentValues, 'shopId')
                        }
                        disabled={isModified}
                        itemTemplate={({
                          label,
                          value,
                          isOccupied,
                          shopId,
                          shopCode,
                          shopName,
                        }) => (
                          <div className="w-full flex flex-row align-items-center justify-content-between gap-1">
                            <div>{label}</div>
                            {isOccupied && (
                              <Badge
                                className="border-round-xs"
                                {...(shopId ===
                                  _.get(currentValues, 'shopId') && {
                                  severity: 'danger',
                                })}
                                value={`[${shopCode}] ${shopName}`}
                              />
                            )}
                          </div>
                        )}
                        error={formState.errors[field.name]}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 sm:col-6 lg:col-3">
              <div className="field m-0">
                <label htmlFor="insurance">
                  {/* <i className="text-red-400 pi pi-check mr-1" /> */}
                  보험사 코드
                </label>
                <div className="p-inputgroup">
                  <Controller
                    control={control}
                    name="insuranceCode"
                    defaultValue={''}
                    rules={{
                      required: '필수 입력항목입니다.',
                    }}
                    render={({ field, formState }) => (
                      <Dropdown
                        id={field.name}
                        {...field}
                        options={_.get(codes, 'insurances')}
                        error={formState.errors[field.name]}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 sm:col-6 lg:col-3">
              <div className="field m-0">
                <label htmlFor="insurancePolicyNumber">
                  {/* <i className="text-red-400 pi pi-check mr-1" /> */}
                  보험증권번호
                </label>
                <div className="p-inputgroup">
                  <Controller
                    control={control}
                    name="insurancePolicyNumber"
                    defaultValue={''}
                    // rules={{
                    //   required: '필수 입력항목입니다.',
                    // }}
                    render={({ field, formState }) => (
                      <InputText id={field.name} {...field} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </Panel>

        <Panel header="2. 점검장 상세 정보" className="pt-3">
          <div className="grid">
            <div className="col-12 sm:col-6">
              <div className="field mb-0">
                <label>주소</label>
              </div>
              <div className="p-inputgroup">
                <Controller
                  control={control}
                  name="postcode"
                  defaultValue=""
                  render={({ field, fieldState, formState }) => (
                    <div className="p-inputgroup-addon">
                      {field.value || '우편번호'}
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="address"
                  defaultValue=""
                  render={({ field, fieldState, formState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      readOnly
                      className="bg-gray-100"
                    />
                  )}
                />
                <Button
                  type="button"
                  label="주소검색"
                  icon="pi pi-search"
                  onClick={() => setIsPostcodeOpen(true)}
                />
              </div>
            </div>
            <div className="col-12 sm:col-6">
              <Controller
                control={control}
                name="addressDetail"
                defaultValue=""
                render={({ field, fieldState, formState }) => (
                  <Titled.InputText
                    id={field.name}
                    title="상세주소"
                    {...field}
                    disabled={_.isEmpty(watch('address'))}
                  />
                )}
              />
            </div>
            {getFilteredInputData(formField, 'businessInfo').map(
              (item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-2">
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}
          </div>
        </Panel>

        <Panel header="3. 대표자 정보" className="pt-3">
          <div className="grid">
            {getFilteredInputData(formField, 'picInfo').map((item, idx) => (
              <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                <Controlled.InputText
                  item={item}
                  key={idx}
                  control={control}
                  setValue={setValue}
                />
              </div>
            ))}
          </div>
        </Panel>

        <Panel header="4. 마스터 계정 정보" className="pt-3">
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-3">
              <Controlled.ValidateInputText
                id={shopId}
                control={control}
                trigger={trigger}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'loginId'
                  )[0].title,
                  dataLabel: 'loginId',
                }}
                inputConfig={{
                  placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                  autoComplete: 'off',
                }}
                rules={{
                  required: '필수 입력 항목입니다.',
                  // pattern: {
                  //   value: CommonUtil.Pattern['login'],
                  //   message: '유효하지 않은 포맷입니다.',
                  // },
                }}
              />
            </div>

            {!isModified &&
              getFilteredInputData(formField, 'password').map((item, idx) => (
                <div key={`col_${idx}`} className="col-12 sm:col-6 lg:col-3">
                  <Controlled.InputPassword
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    inputConfig={{
                      autoComplete: 'off',
                    }}
                  />
                </div>
              ))}
          </div>
        </Panel>
      </form>

      <Postcode
        open={isPostcodeOpen}
        onHide={() => setIsPostcodeOpen(false)}
        onComplete={(data) => {
          console.log(data);

          setIsPostcodeOpen(false);
          setValue('postcode', data.zonecode);
          setValue('address', data.address);
        }}
      />
    </Modal.Form>
  );
};

import { Button } from 'primereact/button';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import React from 'react';

const PopupInputText = ({
  onClickInputs,
  item,
  control,
  classNameProps,
  placeholder,
}) => {
  return (
    <div
      className="p-inputgroup"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClickInputs();
      }}
    >
      <span
        className={`${
          classNameProps ? 'input-border-primary' : 'p-inputgroup-addon'
        } w-full font-bold text-center min-w-3 w-4 max-w-5`}
      >
        {item.label}
      </span>
      <Controller
        control={control}
        name={item.code}
        defaultValue=""
        rules={{
          required: item.required ? '필수 입력 항목입니다' : false,
        }}
        render={({ field, fieldState, formState }) => (
          <InputText
            id={field.name}
            {...field}
            className="p-inputtext p-component bg-gray-100"
            readOnly
            placeholder={placeholder ? placeholder : item.label}
            error={formState.errors[field.name]}
          />
          // <></>
        )}
      />
      <Button
        icon="pi pi-search"
        className={classNameProps ? 'p-button-primary' : 'p-button-warning'}
        // label={`${item.label}조회`}
        htmlFor={item.code}
        type={'button'}
      />
    </div>
  );
};

export default PopupInputText;

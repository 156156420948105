import React, { forwardRef } from 'react';

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Panel } from 'primereact/panel';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';

const getDisplay = (value) => {
  let label = '';

  switch (value) {
    case '1':
      label = '';
      break;
    case '2':
      label = 'X';
      break;
    case '3':
      label = 'W';
      break;
    case '4':
      label = 'C';
      break;
    case '5':
      label = 'A';
      break;
    case '6':
      label = 'U';
      break;
    case '7':
      label = 'T';
      break;
    default:
  }

  return label;
};

const PartRecordMenu = forwardRef((props, ref) => {
  const {
    id,
    index,
    partItem,
    menuRef,
    guideRef,
    displayGuide = false,
    selected,
    onChange,
  } = props;

  const title = `${partItem.partNumber}.${partItem.title}`;
  const items = [
    {
      label: title,
      template: (item, options) => {
        return (
          <div className="flex flex-auto align-items-center justify-content-center py-1">
            <span
              className="font-bold text-center"
              style={{ wordBreak: 'keep-all' }}
            >
              {item.label}
            </span>
          </div>
        );
      },
    },
    {
      separator: true,
    },
    {
      label: '교환',
      value: 'X',
      severity: 'danger',
      command: () => onChange('2'),
      template: MenuItem,
    },
    {
      label: '판금 또는 용접',
      value: 'W',
      severity: 'info',
      command: () => onChange('3'),
      template: MenuItem,
    },
    {
      label: '부식',
      value: 'C',
      severity: 'warning',
      command: () => onChange('4'),
      template: MenuItem,
    },
    {
      label: '흠집',
      value: 'A',
      severity: 'warning',
      command: () => onChange('5'),
      template: MenuItem,
    },
    {
      label: '요철',
      value: 'U',
      severity: 'warning',
      command: () => onChange('6'),
      template: MenuItem,
    },
    {
      label: '손상',
      value: 'T',
      severity: 'warning',
      command: () => onChange('7'),
      template: MenuItem,
    },
    {
      separator: true,
    },
    {
      label: '선택 취소',
      value: null,
      command: () => onChange('1'),
      template: (item, options) => {
        return (
          <span
            className={classNames(options.className, 'py-2')}
            onClick={options.onClick}
          >
            <div className="flex flex-auto align-items-center justify-content-start gap-2">
              <span className="pi pi-ban" />
              <span className={options.labelClassName}>{item.label}</span>
            </div>
          </span>
        );
      },
    },
  ];

  if (displayGuide) {
    return (
      <>
        <OverlayPanel
          ref={(el) => (guideRef.current[index] = el)}
          showCloseIcon
          id={id}
          style={{ width: '450px' }}
        >
          <Panel header={`[${title}] 진단방법`}>
            <div className="flex flex-auto flex-column align-items-center justify-content-center">
              <iframe
                width="400"
                height="224"
                src="https://www.youtube.com/embed/hKbYX74Va-U?autoplay=1&mute=1"
                title="Welcome to Carmon!"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
              <Tag
                className="mt-3 text-lg"
                severity="warning"
                icon="pi pi-exclamation-circle"
              >
                이상 부위 입력은 진단방법조회를 끄고 진행하세요.
              </Tag>
            </div>
          </Panel>
        </OverlayPanel>
        <Button
          type="button"
          className="p-button-rounded p-button-info p-button-raised"
          icon="pi pi-video"
          style={{
            width: 26,
            height: 26,
            position: 'absolute',
            top: partItem.pos[0],
            left: partItem.pos[1],
            zIndex: 99,
          }}
          onClick={(e) => guideRef.current[index].toggle(e)}
          aria-haspopup
          aria-controls={id}
          tooltip={title}
        />
      </>
    );
  } else {
    return (
      <div ref={ref}>
        <Menu
          model={items}
          popup
          ref={(el) => (menuRef.current[index] = el)}
          id={id}
        />
        <Button
          type="button"
          className={classNames(
            'p-0 font-bold border-900 p-button-raised text-black-alpha-80',
            {
              'bg-white': !selected || selected === '1',
              'p-button-danger text-white': ['2'].includes(selected),
              'p-button-info text-white': ['3'].includes(selected),
              'p-button-warning': ['4', '5', '6', '7'].includes(selected),
            }
          )}
          // className={classNames(
          //   'p-0 font-bold border-900 p-button-raised text-black-alpha-80',
          //   {
          //     'bg-white': !selected || selected === '',
          //     'p-button-danger text-white': ['X'].includes(selected),
          //     'p-button-info text-white': ['W'].includes(selected),
          //     'p-button-warning': ['C', 'A', 'U', 'T'].includes(selected),
          //   }
          // )}
          style={{
            width: 26,
            height: 26,
            position: 'absolute',
            top: partItem.pos[0],
            left: partItem.pos[1],
            zIndex: 99,
          }}
          label={getDisplay(selected) || partItem.partNumber}
          // label={selected !== '' ? selected : partItem.partNumber}
          onClick={(e) => {
            // if (selected) {
            //   onClear();
            // } else {
            menuRef.current[index].toggle(e);
            // }
          }}
          aria-controls={id}
          aria-haspopup
          tooltip={title}
        />
      </div>
    );
  }
});

const MenuItem = (item, options) => {
  return (
    // <a
    //   className={classNames(options.className, 'py-2')}
    //   onClick={options.onClick}
    // >
    //   <div className="flex flex-auto align-items-center justify-content-start gap-2">
    //     <Badge value={item.value} severity={item.severity} />
    //     <span className={options.labelClassName}>{item.label}</span>
    //   </div>
    // </a>
    <div
      className={classNames(
        options.className,
        'flex flex-auto align-items-center justify-content-start gap-2 py-2'
      )}
      onClick={options.onClick}
    >
      <Badge value={item.value} severity={item.severity} />
      <span className={options.labelClassName}>{item.label}</span>
    </div>
  );
};

export default PartRecordMenu;
